import { queryFetch, useReactQuery } from "@cultureamp/frontend-apis"
import dynamic from "next/dynamic"
import styles from "./TitleTile.module.scss"

const Loading = () => <div className={styles.loadingPlaceholder} />

const HomeTitleBlock = dynamic(
  () => import("src/components/HomeTitleBlock/HomeTitleBlock"),
  { loading: Loading },
)

const TitleTile: React.FC<{
  hasFeedbackCTA?: boolean
  hasPerformanceAnytimeFeedbackRebuildEnabled?: boolean
  isWarmup?: boolean
}> = ({
  hasFeedbackCTA,
  hasPerformanceAnytimeFeedbackRebuildEnabled,
  isWarmup,
}) => {
  const { isLoading } = useReactQuery({
    queryKey: ["configuration"],
    queryFn: () => queryFetch("configuration"),
  })

  if (isWarmup || isLoading) {
    return <Loading />
  }

  return (
    <HomeTitleBlock
      hasFeedbackCTA={hasFeedbackCTA}
      hasPerformanceAnytimeFeedbackRebuildEnabled={
        hasPerformanceAnytimeFeedbackRebuildEnabled
      }
    />
  )
}

export default TitleTile

import { Navigation } from "src/components/Navigation/Navigation"
import {
  intercomCallback,
  toggleIntercomLauncher,
  useKeyContentPaint,
} from "@cultureamp/next-services"
import { queryFetch, useReactQuery } from "@cultureamp/frontend-apis"
import { useIntl } from "@cultureamp/i18n-react-intl"
import { useLocalStorageValue } from "@react-hookz/web"
import Content from "./Content"
import Head from "next/head"
import React, { useEffect } from "react"
import Spacer from "src/components/Spacer/Spacer"

export const UnifiedHome = () => {
  const intl = useIntl()
  useKeyContentPaint("TaskListRendered")

  const { data, isLoading } = useReactQuery({
    queryKey: ["home/flags"],
    queryFn: () => queryFetch("home/flags"),
  })

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.hash.includes("#help")
    ) {
      toggleIntercomLauncher("showWidget")

      // This is required to ensure the Intercom Launcher icon shows on load.
      // Without this the widget shows without the launcher icon
      // This is due to intercom being unable to update settings while loading
      intercomCallback("onShow", () => toggleIntercomLauncher("show"))
    }
  }, [])

  // we cache layout to local storage to allow us to warm up the components before we manage to fetch it
  // we also fallback to it in case something goes wrong, but in happy path it's used only for warmup
  const { value: storedFlags, set: storeFlags } = useLocalStorageValue<
    typeof data
  >(
    "cache:home/flags",
    { initializeWithValue: false }, // to avoid hydration issues
  )

  useEffect(() => {
    if (data) storeFlags(data)
  }, [data, storeFlags])

  const flags = data ?? storedFlags

  return (
    <>
      <Head>
        <title>
          {(intl
            .formatMessage({
              id: "home.pageTitle",
              defaultMessage: "Home",
              description: "Page title for home page",
            })
            .trim() || "Home") + " - Culture Amp"}
        </title>

        <style
          // override the background-color that is set by the frontend-template https://github.com/cultureamp/frontend-services/blob/1c4fbece8f4fe68f2805b4f82b8ae39b5db7c11b/packages/next-services/lib/providers/kaizenAdapter.tsx
          dangerouslySetInnerHTML={{
            __html: `body { background-color: white }`,
          }}
        />
      </Head>
      <Navigation />
      <Content isLoading={isLoading} flags={flags} />
      <Spacer gap={5} gapOnMediumViewports={4} gapOnSmallViewports={3} />
    </>
  )
}
